/* eslint-disable */

<template>
  <div id="Lgx315088047538438144">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Snippets</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer command-block">192.168.5.0</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">255.255.255.0</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">with-mask</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">mask</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">subnet-mask</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="mt-2 h3 text-white">
              &lt;config xmlns:xc="urn:ietf:params:xml:ns:netconf:base:1.0" xmlns="urn:ietf:params:xml:ns:netconf:base:1.0"><br />
              &nbsp;&nbsp;&lt;native xmlns="http://cisco.com/ns/yang/Cisco-IOS-XE-native"
              xmlns:ios-bgp="http://cisco.com/ns/yang/Cisco-IOS-XE-bgp"><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;router><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:bgp><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:address-family><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:no-vrf><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:ipv4><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:af-name>unicast&lt;/ios-bgp:af-name><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:ipv4-unicast><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:network><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:with-mask><br />

              <div class="flex items-center mb-4">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:number>
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg ml-2 mr-2"
                  id="input0"
                  style="width: 9vw"
                ></vue-draggable-container>
                &lt;/ios-bgp:number><br />
              </div>

              <div class="flex items-center">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg ml-2 mr-2"
                  id="input1"
                  style="width: 9vw"
                ></vue-draggable-container>
                >
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg ml-2 mr-2"
                  id="input2"
                  style="width: 9vw"
                ></vue-draggable-container>
                :/ios-bgp:mask><br />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:with-mask><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:ipv4-unicast><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:ipv4-unicast><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:ipv4><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:no-vrf><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:address-family><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:bgp><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;/router><br />
              &nbsp;&nbsp;&lt;/native><br />
              &lt;/config>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-command';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === '192.168.5.0') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === 'mask') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === '255.255.255.0') {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#Lgx315088047538438144 {
  .answerStandard {
    min-height: 55px;
  }
}

.currently-dragging-standard-command {
  background-color: #0042ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

.command-block {
  display: inline-flex;
  max-height: 100px;
  margin: 5px;
  padding: 10px;
  text-align: left;
  color: #fff;
  border: 1px solid #3f3f3f;
  background-color: #fd4445;
  border-radius: 5px;
}
</style>
